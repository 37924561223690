<template>
  <StaticView :heading="$t('pages.contact')">
    <!-- GERMAN -->
    <div
      v-if="$i18n.locale === 'de'"
      class="grid grid-cols-2 auto-rows-max text-darkerGray leading-9"
    >
      <div class="flex flex-row p-2">
        <div class="px-5">
          <img
            src="@images/address.png"
            srcset="
              @images/address.png    1x,
              @images/address@2x.png 2x,
              @images/address@3x.png 3x
            "
          />
        </div>
        <address class="not-italic">
          <p class="text-[20px] font-semibold mb-[1.25rem] uppercase">
            Adresse
          </p>
          <p>ESG Analytics GmbH</p>
          <p>Von-Werth-Straße 15</p>
          <p>50670 Köln</p>
          <p>Deutschland</p>
        </address>
      </div>
      <div class="flex p-2 leading-9">
        <div class="px-5">
          <img
            src="@images/contact.png"
            srcset="
              @images/contact.png    1x,
              @images/contact@2x.png 2x,
              @images/contact@3x.png 3x
            "
          />
        </div>
        <div>
          <p class="text-[20px] font-semibold mb-[1.25rem] uppercase">
            Kontakt
          </p>
          <p>Bei Fragen/ Problemen mit dem Tool wenden Sie sich bitte an: support@ecore-rating.com</p>
        </div>
      </div>
    </div>

    <!-- ENGLISH -->
    <div
      v-else-if="$i18n.locale === 'en'"
      class="grid grid-cols-2 auto-rows-max text-darkerGray leading-9"
    >
      <div class="flex flex-row p-2">
        <div class="px-5">
          <img
            src="@images/address.png"
            srcset="
              @images/address.png    1x,
              @images/address@2x.png 2x,
              @images/address@3x.png 3x
            "
          />
        </div>
        <address class="not-italic">
          <p class="text-[20px] font-semibold mb-[1.25rem] uppercase">
            Address
          </p>
          <p>ESG Analytics GmbH</p>
          <p>Von-Werth-Straße 15</p>
          <p>50670 Cologne</p>
          <p>Germany</p>
        </address>
      </div>
      <div class="flex p-2 leading-9">
        <div class="px-5">
          <img
            src="@images/contact.png"
            srcset="
              @images/contact.png    1x,
              @images/contact@2x.png 2x,
              @images/contact@3x.png 3x
            "
          />
        </div>
        <div>
          <p class="text-[20px] font-semibold mb-[1.25rem] uppercase">
            Contact us at
          </p>
          <p>If you have any questions/ problems with the tool, please contact: support@ecore-rating.com</p>
        </div>
      </div>
    </div>
  </StaticView>
</template>

<script>
import StaticView from '@/components/layout/StaticView'
export default {
  components: {
    StaticView,
  },
}
</script>
